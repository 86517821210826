<!-- =========================================================================================
	File Name: RegistrationEvent.vue
	Description: Регистрация посетителя на мероприятие
========================================================================================== -->

<template>
  <div id="order-edit">
    <div v-if="!isEditOrderAllowed" class="vx-row">
      <vx-card
          title-color="#fff"
          card-background="danger"
          content-color="#fff">
          <p class="text-center font-bold">
            Редактирование заказа запрещено
          </p>
      </vx-card>
    </div>

    <div v-else class="vx-row">
      <div class="vx-col lg:w-3/5 w-full relative">
        <catalog :order="order" :root_category_id="$globals.EVENTS_ROOT_CATEGORY_ID" :skip_update="skipUpdate" :single_item_order="true" ref="catalog" />
      </div>

      <div class="vx-col lg:w-2/5 w-full">
        <vs-table :data="orderItems" hoverFlat noDataText="Нет товаров в заказе"
          class="mb-4">
          <template slot="thead">
            <vs-th>Название</vs-th>
            <vs-th>Цена</vs-th>
            <vs-th>Количество</vs-th>
            <vs-th>Всего</vs-th>
            <vs-th>Удалить</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium">{{ tr.name }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.price }} руб.</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.item_amount }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.total_price }} руб.</p>
                </vs-td>
                <vs-td>
                  <api-button 
                      :click="() => removeItemFromOrder(tr)"
                      text-color="black" 
                      icon="close" 
                      type="flat">
                  </api-button>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      
        <vx-card v-if="!orderId" class="mb-4">
          <api-button class="w-full mb-2" :click="createTicketOrder">БИЛЕТ + РЕГИСТРАЦИЯ</api-button>
          <api-button class="w-full mb-2" v-if="$acl.check('admin')" :click="registerEvent">РЕГИСТРАЦИЯ</api-button>
          <api-button class="w-full" v-if="$acl.check('admin') && isPrepaidRegistrationAvailable" :click="() => registerEvent(1)">РЕГИСТРАЦИЯ ПО ПРЕДОПЛАТЕ</api-button>
        </vx-card>

        <order-payments v-if="orderId" :order="order" :callback="fixPaymentsCallback"/>
      </div>
    </div>
  </div>
</template>

<script>
var qs = require('qs');
import catalog from './Catalog.vue'
import orderPayments from './eCommerce/OrderPayments.vue'

// TODO что это
let rent_event_type = 7569;
let club_event_type = 7570;
let mix_event_type = 7571;

// TODO mixin with EditOrder.vue
export default {
  data() {
    return {
      order: {
        is_time_order: false,
        visit_id: this.$store.state.activeClient.activeVisit ? this.$store.state.activeClient.activeVisit.id : null,
        status: 0,
        manager_id: JSON.parse(localStorage.getItem('userInfo')).user_id,
        customer_id: this.$store.state.activeClient.id, 
        order_date: new Date(Date.now()).toString(),
        time: Date.now(),
        tariff_object_id: this.$store.getters.activeTimeTariff.object_id,
        client_type_id: this.$store.state.activeClient.info.client_type.id,
        items: [],
        payments: []
      },
    }
  },
  computed: {
    skipUpdate() {
      return (this.order.id) ? false : true;
    },
    orderId() {
      return this.order.id;
    },
    orderItems() {
      return this.order.items;
    },
    orderTotalPrice() {
      return this.$store.getters['eCommerce/orderTotalPrice'](this.order)
    },
    isPrepaidRegistrationAvailable() {
      if (this.order.items.length > 0) {
        let event_type = parseInt(this.order.items[0].event_type, 10);
        return (event_type === this.$globals.RENT_EVENT_TYPE) || (event_type === this.$globals.MIX_EVENT_TYPE);
      }
      return false;
    }
  },
  asyncComputed: {
    isEditOrderAllowed: {
      async get() {
        let permission = await this.$store.dispatch('isEditOrderAllowed', this.order);
        return permission;
      },
      watch: ['order.status']
    },
    async isMyOrder() {
      let permission = await this.$store.dispatch('isMyOrder', this.order);
      return permission;
    }
  },
  methods: {
      removeItemFromOrder(item) {
        let callback = () => { 
          this.$refs.catalog.getCatalogItems();
        };
        this.$store.dispatch('eCommerce/removeItemFromOrder', {item: item, order: this.order, skip_update: this.skipUpdate, callback: callback});
      },
      async createTicketOrder() {
        if (!this.orderItems.length) return;
        try {
          let result = await this.$store.dispatch('addOrder', {
            payload: this.order
          });
          this.order = result;
          await this.addEventTariffs(result.items[0].ticket_ids[0]);
        } catch (error) {
          this.$vs.notify({
            title:'Ошибка при создании заказа',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })    
        }
      },
      async registerEvent(is_prepaid = 0) {
        await this.addEventTariffs(null, is_prepaid)
        this.$router.push('/');
      },
      async addEventTariffs(ticket_id = null, is_prepaid = null) {
        if (!this.orderItems.length) return;

        let event = this.orderItems[0];
        let event_props = await this.getEventProps(event.object_id);
        let tariff = this.$store.state.timeTariffsList.find((e) => (e.objectID === event_props.time_tariff[0].id));
        // Тариф связанный с мероприятием
        let tariff_item = {
          objectID: event_props.time_tariff[0].id,
          start_time: Math.max(this.$store.state.now, event_props.event_start_time),
          end_time: event_props.event_end_time,
          event_id: event.objectID,
          tariff_ticket: ticket_id,
          item_amount: 0,
          is_prepaid: is_prepaid
        };
        try {
          await this.$store.dispatch('eCommerce/addTimeTariff', tariff_item);
          if (tariff.price > 0) {
            let continue_tariff_item = Object.assign({}, tariff_item);
            continue_tariff_item.start_time = event_props.event_end_time;
            continue_tariff_item.end_time = false;
            continue_tariff_item.event_id = null;
            continue_tariff_item.tariff_ticket = null,
            continue_tariff_item.item_amount = 0;
            continue_tariff_item.is_prepaid = 0;
            await this.$store.dispatch('eCommerce/addTimeTariff', continue_tariff_item)
          }
        } catch (error) {
          this.$vs.notify({
            title:'Ошибка при добавлении тарифа',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })          
        }
      },
      async getEventProps($event_object_id) {
        let response;
        try {
          response = await this.$http({
            method: 'post',
            url: '/udata/users/get_object/' + $event_object_id + '/' + 'event_props' + '/.json',
            data: qs.stringify({field_groups: ['event_props']}),
            withCredentials: true
          })
        } catch (error) {
          this.$vs.notify({
            title:'Ошибка получения тарифа, связанного с мероприятием',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })
        }
        if (!response.data.error) {
          return response.data
        } else {
          this.$vs.notify({
            title:'Ошибка получения тарифа, связанного с мероприятием',
            text: response.data.error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })    
        }
      },
      fixPaymentsCallback() {
        this.$router.back();
      },
  },
  created() {
      if (this.order == null) {
        this.$router.push('/');
      }
  },
  components: {
    catalog,
    orderPayments,
  }
}
</script>

<style lang="scss" scoped>
#order-edit {
  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
  }

  .item-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
}
</style>
